import { useState } from 'react';
import './add-category.css';
import { profile } from 'console';

const AddCategory = () => {
  const [profileImg, setProfileImg] = useState<string | null>(null);

  const handleChangeImg = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      console.log(reader);
      reader.onloadend = () => {
        setProfileImg(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="add-category">
      <p>Добавить категорию</p>
      <div className="add-category-photo">
        <input
          type="file"
          id="photo-upload"
          accept="image/png, image/jpeg"
          onChange={handleChangeImg}
          style={{ display: 'none' }}
        />

        {profileImg ? (
          <img
            src={profileImg}
            alt="Category"
            className="category-img-preview"
          />
        ) : (
          <label htmlFor="photo-upload">
            <div className="">+</div>
          </label>
        )}
      </div>

      <form action="" className="add-category-form">
        <input type="text" placeholder="Название категории" />
        <button type="submit">Добавить</button>
      </form>
    </div>
  );
};

export default AddCategory;
