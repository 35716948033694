import React from 'react';
import Header from '../components/header/Header';
import CreateCategory from '../components/create-category/CreateCategory';
import CategoryList from '../components/category-list/CategoryList';

const ShopPage = () => {
  return (
    <div className="page shop-page">
      <Header />

      <CreateCategory />
      <CategoryList />
    </div>
  );
};

export default ShopPage;
