import axios from 'axios';
import { mainURL } from '../widgets/config';

export const verifyPhoneNumber = async (phoneNumber: string) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/send-login-code/',
      { phone_number: phoneNumber },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Response:', response.data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Login failed');
    } else {
      throw new Error('Login failed: Network or server error');
    }
  }
};

export const loginByCode = async (phoneNumber: string, confirmationCode: string) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/login-by-code/',
      { password: confirmationCode, phone_number: phoneNumber},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    localStorage.setItem('accessToken', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Verification failed');
    } else {
      throw new Error('Verification failed: Network or server error');
    }
  }
}

