import React from 'react';
import { StepPropsType } from '../../widgets/types';

const Step1 = ({formData, handleChange, }: StepPropsType) => {
  return (
    <div className="step1">
      <div className="personal-info">
        <p className="title">Персональная информация</p>
        <input
          type="text"
          placeholder="Имя"
          value={formData.firstName}
          onChange={handleChange('firstName')}
        />
        <input
          type="text"
          placeholder="Фамилия"
          value={formData.lastName}
          onChange={handleChange('lastName')}
        />
        <input
          type="date"
          placeholder="Дата дня рождения"
          value={formData.birthDate}
          onChange={handleChange('birthDate')}
        />
      </div>

      <div className="contacts">
        <p className="title">Ваши контакты</p>
        <input
          type="text"
          placeholder="Электронная почта"
          value={formData.email}
          onChange={handleChange('email')}
        />
        <input
          type="number"
          placeholder="Телефонный номер"
          value={formData.phoneNumber}
          onChange={handleChange('phoneNumber')}
        />
      </div>
    </div>
  );
};

export default Step1;
