import React from 'react'
import Header from '../components/header/Header';

const RequestsPage = () => {
  return (
    <div className="page requests-page">
      <Header />
    </div>
  );
}

export default RequestsPage