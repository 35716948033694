import React from 'react'
import Header from '../components/header/Header'
import SettingsButtons from '../components/settings-buttons/SettingsButtons'

const SettingsPage = () => {
  return (
    <div className='page settings-page'>
        <Header />
        <SettingsButtons />
    </div>
  )
}

export default SettingsPage