import "./app.css"
import { HashRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SettingsPage from './pages/SettingsPage';
import ChatsPage from './pages/ChatsPage';
import ShopPage from './pages/ShopPage';
import AnalyticsPage from './pages/AnalyticsPage';
import RequestsPage from './pages/RequestsPage';
import AddCategoryPage from './pages/AddCategoryPage';
import CreateShopPage from './pages/CreateShopPage';
import CreateSupplierPage from './pages/CreateSupplierPage';
import EditShopPage from './pages/EditShopPage';
import AddProductPage from './pages/AddProductPage';
import MainPage from "./pages/MainPage";

function App() {
  return (
    <div className="app">
      <HashRouter>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/requests' element={<RequestsPage />} />
          <Route path='/chats' element={<ChatsPage />} />
          <Route path='/shop' element={<ShopPage />} />
          <Route path='/shop/edit-shop' element={<EditShopPage />} />
          <Route path='/shop/add-product' element={<AddProductPage />} />
          <Route path='/shop/add-category' element={<AddCategoryPage />} />
          <Route path='/analytics' element={<AnalyticsPage />} />
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='/settings/create-shop' element={<CreateShopPage />} />
          <Route path='/settings/create-supplier' element={<CreateSupplierPage />} />
          
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
