import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../static/img/stubla_logo.png"
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CreateSupplierForm from '../components/create-supplier-form/CreateSupplierForm';

const CreateSupplierPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className='page create-shop-page'>
      <div className="go-back-button" onClick={handleGoBack}>
        <ArrowBackOutlinedIcon fontSize='large'/>
      </div>
      <div className="login-page_logo">
          <img src={logo} alt='Logo'/>
        </div>
        <CreateSupplierForm />
    </div>
  )
}

export default CreateSupplierPage