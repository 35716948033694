import React from 'react'
import { StepPropsType } from '../../widgets/types'

const Step3Supplier = ({formData, handleChange}: StepPropsType) => {
  return (
    <div className="step3">
      <div className="shop-name">
        <p className="title">Название компании</p>
        <input
          type="text"
          placeholder="Название компании"
          value={formData.shopName}
          onChange={handleChange('shopName')}
        />
      </div>
      <div className="shop-description">
        <p className="title">Описание компании</p>
        <input
          type="text"
          placeholder="Описание компании"
          value={formData.shopDescription}
          onChange={handleChange('shopDescription')}
        />
      </div>
      <div className="shop-address">
        <p className="title">Адрес склада</p>
        <input
          type="text"
          placeholder="Выберите адрес вашего склада"
          value={formData.shopAddress}
          onChange={handleChange('shopAddress')}
        />
      </div>
      <div className="agreement-checkbox">
        <input
          type="checkbox"
          id="agreement"
          checked={formData.agreement}
          onChange={handleChange('agreement')}
        />
        <label htmlFor="agreement">
          Согласиться с условиями использования и соглашением
        </label>
      </div>
    </div>
  )
}

export default Step3Supplier