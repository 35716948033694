import './about-us.css';
import shopping from '../../static/img/shopping.webp';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const AboutUs = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('form'); // 'form' or 'comparison'
  const [loading, setLoading] = useState(true);

  const handleLogin = () => {
    navigate('/login');
  };

  const openModal = (tab: string) => {
    setIsModalOpen(true);
    setActiveTab(tab);
    document.body.classList.add('no-scroll');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLoading(true);
    document.body.classList.remove('no-scroll');
  };

  useEffect(() => {
    return () => document.body.classList.remove('no-scroll');
  }, []);

  return (
    <div className="about-us">
      <div className="main-info-title">
        <h1>Запустите свой мировой бренд!</h1>
        <p className="description">
          Управляйте своим делом из любой точки Земного шара и продавайте по
          всему Казахстану
        </p>
        <div className="buttons">
          <button className="cta-button" onClick={handleLogin}>
            Запустить свой бизнес сейчас!
          </button>
          <button
            className="open-modal-button"
            onClick={() => openModal('form')}
          >
            Открыть форму регистрации
          </button>
        </div>
      </div>
      <div className="main-info">
        <div className="additional-info">
          <h2 className="subtitle">Почему Stubla:</h2>
          <ul className="features">
            <li>
              •⁠ Сконцентрируйтесь на бизнесе — экономьте миллионы на расходах!
            </li>
            <li>•⁠ Никакой аренды – забудьте про дорогие помещения и офисы.</li>
            <li>
              •⁠ ⁠Никаких коммунальных платежей – все эти траты остаются в
              прошлом.
            </li>
            <li>
              •⁠ ⁠Всё онлайн – управляйте своим бизнесом, где бы вы ни
              находились.
            </li>
          </ul>
          <p className="cta">
            Начните прямо сейчас и сосредоточьтесь на создании бренда, а не на
            расходах.
          </p>
          <br />
          <button
            className="cta-button"
            onClick={() => openModal('comparison')}
          >
            Узнать подробнее
          </button>
        </div>
      </div>
      <p className="closing-message">
        Не упустите возможность запустить бизнес и бренд, не тратя миллионы на
        аренду и коммунальные расходы. Зарегистрируйтесь на Stubla и начните
        путь к успеху, продавая по всему Казахстану.
      </p>

      {/* Unified Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>

            {activeTab === 'form' && (
              <div className="tab-content">
                {loading && (
                  <p className="loading-text">Форма загружается...</p>
                )}
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSeaXadK3RcZ-6SOxBxOJRgDUC6EJ3BAQ5vfwUGSUIcuPocp0A/viewform?embedded=true"
                  height="600"
                  width={"100%"}
                  title="Google Form"
                  onLoad={() => setLoading(false)}
                >
                  Загрузка…
                </iframe>
              </div>
            )}

            {activeTab === 'comparison' && (
              <div className="tab-content">
                <p className='cta'> Почему выбрать Stubla?</p>
                <table className="comparison-table">
                  <thead>
                    <tr>
                      <th>Stubla</th>
                      <th>Конкуренты</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Виртуальная витрина: всего 49,900 тг в месяц</td>
                      <td>
                        Аренда помещения: расходы до 5,000,000 тг/мес +
                        коммунальные, ремонт, обслуживание
                      </td>
                    </tr>
                    <tr>
                      <td>Сэкономьте до 15 млн в год на аренде</td>
                      <td>
                        До 20 млн в год на аренду, коммунальные, зарплаты и
                        риски
                      </td>
                    </tr>
                    <tr>
                      <td>Автоматическая, бесплатная доставка</td>
                      <td>
                        Ручная доставка: риски потери и повреждения товара, доп.
                        ресурсы
                      </td>
                    </tr>
                    <tr>
                      <td>Гибкость управления: из любой точки мира</td>
                      <td>Привязанность к физической локации</td>
                    </tr>
                    <tr>
                      <td>
                        Прозрачные тарифы: 49,900 тг/мес без скрытых затрат
                      </td>
                      <td>Скрытые комиссии и непредвиденные расходы</td>
                    </tr>
                    <tr>
                      <td>Фокус на качестве и эстетике продуктов</td>
                      <td>Перенасыщенность низкокачественными товарами</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
