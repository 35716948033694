import React from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AddCategory from '../components/add-category/AddCategory';
import { useNavigate } from 'react-router-dom';

const AddCategoryPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="page add-category-page">
      <div className="go-back-button" onClick={handleGoBack}>
        <ArrowBackOutlinedIcon fontSize='large'/>
      </div>

      <AddCategory />
    </div>
  );
};

export default AddCategoryPage;
