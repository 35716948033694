import React from "react";
import { useNavigate } from "react-router-dom";
import "./settings-buttons.css";

const SettingsButtons = () => {
  const navigate = useNavigate();

  return (
    <div className="settings-buttons">
      <button onClick={() => navigate("/settings/create-shop")}>Создать магазин</button>
      <button onClick={() => navigate("/settings/create-supplier")}>Создать поставщика</button>
    </div>
  );
};

export default SettingsButtons
