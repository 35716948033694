import "./category-list.css"

const CategoryList = () => {
  return (
    <div className="category-list">
        <p className="category-title">Список категорий</p>
    </div>
  )
}

export default CategoryList