import React from 'react';
import logo from '../static/img/stubla_logo.png';
import LoginForm from '../components/login-form/LoginForm';
import GoBackButton from '../elements/go-back-button/GoBackButton';

const LoginPage = () => {
  return (
    <div className="page login-page">
      <div className="login-page_logo">
        <GoBackButton />
        <img src={logo} alt="Logo" />
      </div>

      <LoginForm />
    </div>
  );
};

export default LoginPage;
