import React from 'react';
import { StepPropsType } from '../../widgets/types';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';

const Step2 = ({ formData, handleChange }: StepPropsType) => {
  return (
    <div className="step2">
      <div className="passport-info">
        <p className="title">Паспортная информация</p>
        <input
          type="number"
          placeholder="Номер паспорта"
          value={formData.passportNumber}
          onChange={handleChange('passportNumber')}
        />
        <input
          type="text"
          placeholder="Орган выдачи паспорта"
          value={formData.passportIssuer}
          onChange={handleChange('passportIssuer')}
        />
      </div>
      <div className="passport-front-side">
        <p className="title">Передняя сторона паспорта</p>
        <div className="label-for-document-input-file">
          <label htmlFor="passport-front">
            <PermMediaOutlinedIcon />
          </label>
        </div>
        <input type="file" id="passport-front" style={{ display: 'none' }} />
      </div>
      <div className="business-info">
        <p className="title">Бизнес информация</p>
        <input
          type="text"
          placeholder="Номер регистрации бизнеса"
          value={formData.businessNumber}
          onChange={handleChange('businessNumber')}
        />
      </div>
      <div className="business-license">
        <p className="title">Скан регистрации бизнеса</p>
        <label
          htmlFor="business-license"
          className="label-for-document-input-file"
        >
          <PermMediaOutlinedIcon />
        </label>
        <input type="file" id="business-license" style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default Step2;
