import React from 'react';
import logo from '../static/img/stubla_logo.png';
import AboutUs from '../components/about-us/AboutUs';

const MainPage = () => {
  return (
    <div className="page main-page">
      <div className="login-page_logo">
        <img src={logo} alt="Logo" />
      </div>

      <AboutUs />
    </div>
  );
};

export default MainPage;
