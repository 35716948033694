import { useNavigate } from 'react-router-dom';
import './create-category.css';

const CreateCategory = () => {
  const navigate = useNavigate();

  const handleEditShop = () => {
    navigate('/shop/edit-shop');
  };
  const handleAddProduct = () => {
    navigate('/shop/add-product');
  };
  const handleAddCategory = () => {
    navigate('/shop/add-category');
  };

  return (
    <div className="create-category">
      <div className="create-category-buttons">
        <button className="edit-shop" onClick={handleEditShop}>Изменить магазин</button>
        <button className="add-product" onClick={handleAddProduct}>Добавить продукт</button>
      </div>
      <p className="category-title">Создать категорию</p>
      <div className="create-category-container">
        <div className="plus" onClick={handleAddCategory}>
          <span>+</span>
        </div>
        <span>Добавить</span>
      </div>
    </div>
  );
};

export default CreateCategory;
