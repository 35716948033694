import React from 'react'

const EditShopPage = () => {
  return (
    <div className='page edit-shop-page'>
        изменить магазин
    </div>  
  )
}

export default EditShopPage