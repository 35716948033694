import React from 'react'
import logo from "../static/img/stubla_logo.png"
import CreateShopForm from '../components/create-shop-form/CreateShopForm'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';


const CreateShopPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className='page create-shop-page'>
      <div className="go-back-button" onClick={handleGoBack}>
        <ArrowBackOutlinedIcon fontSize='large'/>
      </div>
      <div className="login-page_logo">
          <img src={logo} alt='Logo'/>
        </div>
        <CreateShopForm />
    </div>
  )
}

export default CreateShopPage