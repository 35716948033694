import { useState } from 'react';
import './login-form.css';
import { loginByCode, verifyPhoneNumber } from '../../utils/fetch';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isError, setIsError] = useState<null | string>(null);
  const [showConfirmationInput, setShowConfirmationInput] = useState(false);

  const handleSetPhoneNumber = (e: any) => {
    setIsError(null);
    const input = e.target.value;

    const numericInput = input.slice(2).replace(/\D/g, '');
    setPhoneNumber(numericInput);
  };

  const handleSetConfirmationCode = (e: any) => {
    setIsError(null);
    setConfirmationCode(e.target.value);
  };

  const handleCheckPhoneNumber = async (e: any) => {
    e.preventDefault();
    try {
      const response = await verifyPhoneNumber(`+7${phoneNumber}`);

      if (response.message === 'ok') {
        setIsError(null);
        setShowConfirmationInput(true);
      }
    } catch (err) {
      setIsError('Invalid phone number');
      console.error('Error:', err);
    }
  };

  const handleLoginByCode = async (e: any) => {
    e.preventDefault();

    try {
      const response = await loginByCode(`+7${phoneNumber}`, confirmationCode);

      if (response) {
        navigate('/settings');
      }
    } catch (err) {
      setIsError('Failed to login with provided code');
      console.error('Error:', err);
    }
  };

  return (
    <div className="login-form">
      <form
        onSubmit={
          showConfirmationInput ? handleLoginByCode : handleCheckPhoneNumber
        }
      >
        <input
          type="text"
          placeholder="Номер телефона"
          value={`+7${phoneNumber}`}
          onChange={handleSetPhoneNumber}
          className={
            isError && !showConfirmationInput
              ? 'login-input error'
              : 'login-input'
          }
          maxLength={12}
        />

        {showConfirmationInput && (
          <input
            type="text"
            placeholder="Код подтверждения"
            value={confirmationCode}
            onChange={handleSetConfirmationCode}
            className={isError ? 'login-input error' : 'login-input'}
            maxLength={6}
          />
        )}
        {isError && <p className="error-text">{isError}</p>}
        <button type="submit" className="login-button">
          Войти
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
