import React from 'react'
import Header from '../components/header/Header';
import ChatsList from '../components/chats-list/ChatsList';

const ChatsPage = () => {
    return (
        <div className="page chats-page">
          <Header />
          <ChatsList />
        </div>
      );
}

export default ChatsPage