import React from 'react'

const AddProductPage = () => {
  return (
    <div className='page add-product-page'>
      добавить продукт
    </div>
  )
}

export default AddProductPage