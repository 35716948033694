import React from 'react';
import Header from '../components/header/Header';

const AnalyticsPage = () => {
  return (
    <div className="page analytics-page">
      <Header />
    </div>
  );
};

export default AnalyticsPage;
