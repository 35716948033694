import { useState } from 'react';
import './create-shop-form.css';
import { createShopFormDataType } from '../../widgets/types';
import Step1 from '../create-form-steps/Step1';
import Step2 from '../create-form-steps/Step2';
import Step3Shop from '../create-form-steps/Step3Shop';
import "../create-form-steps/step.css"

const CreateShopForm = () => {
  const [formStepNumber, setFormStepNumber] = useState(1);
  const [formData, setFormData] = useState<createShopFormDataType>({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phoneNumber: '',
    passportNumber: '',
    passportIssuer: '',
    passportFront: null,
    businessNumber: '',
    shopCategory: '',
    shopName: '',
    shopDescription: '',
    shopAddress: '',
    agreement: false,
  });

  const handleChange = (input: any) => (e: any) => {
    const value = input === 'agreement' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [input]: value });
  };

  // Go to the next step
  const nextStep = () => setFormStepNumber(formStepNumber + 1);

  // Go to the previous step
  const prevStep = () => setFormStepNumber(formStepNumber - 1);

  // Submit form
  const handleSubmit = () => {
    alert(
      `Регистрация завершена!\nДанные: ${JSON.stringify(formData, null, 2)}`
    );
  };

  return (
    <>
      <div className="create-shop-form">
        <div className="create-shop-form-container">
          {/* Step 1: Personal and Contact Information */}
          {formStepNumber === 1 && (
            <Step1 formData={formData} handleChange={handleChange} />
          )}

          {/* Step 2: Passport and Business Information */}
          {formStepNumber === 2 && (
            <Step2 formData={formData} handleChange={handleChange} />
          )}

          {/* Step 3: Shop Information */}
          {formStepNumber === 3 && (
            
            <Step3Shop formData={formData} handleChange={handleChange} />
          )}

          {/* Navigation Buttons */}
          <div className="add-shop-buttons">
            {formStepNumber > 1 && (
              <button type="button" onClick={prevStep}>
                Назад
              </button>
            )}
            {formStepNumber < 3 ? (
              <button type="button" onClick={nextStep}>
                Далее
              </button>
            ) : (
              <button type="button" onClick={handleSubmit}>
                Создать магазин
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateShopForm;
