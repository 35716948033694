import React from 'react';
import { StepPropsType } from '../../widgets/types';

const Step3Shop = ({ formData, handleChange }: StepPropsType) => {
  return (
    <div className="step3">
      <div className="choose-shop-category">
        <p className="title">Категория вашего магазина</p>
        <input
          type="text"
          placeholder="Выберите категории вашего магазина"
          value={formData.shopCategory}
          onChange={handleChange('shopCategory')}
        />
        <div className="add-shop_categories-list">
          Здесь будут выбранные категории вашего магазина
        </div>
      </div>
      <div className="shop-name">
        <p className="title">Название магазина</p>
        <input
          type="text"
          placeholder="Название магазина"
          value={formData.shopName}
          onChange={handleChange('shopName')}
        />
      </div>
      <div className="shop-description">
        <p className="title">Описание магазина</p>
        <input
          type="text"
          placeholder="Описание магазина"
          value={formData.shopDescription}
          onChange={handleChange('shopDescription')}
        />
      </div>
      <div className="shop-address">
        <p className="title">Адрес магазина</p>
        <input
          type="text"
          placeholder="Выберите адрес вашего магазина"
          value={formData.shopAddress}
          onChange={handleChange('shopAddress')}
        />
      </div>
      <div className="agreement-checkbox">
        <input
          type="checkbox"
          id="agreement"
          checked={formData.agreement}
          onChange={handleChange('agreement')}
        />
        <label htmlFor="agreement">
          Согласиться с условиями использования и соглашением
        </label>
      </div>
    </div>
  );
};

export default Step3Shop;
